<template>
  <div>
    <v-card class="mt-4">
      <v-card-title> Upload 3PL File </v-card-title>

      <v-card-text>
        <ul>
          <li>
            Upload an Excel file returned by the 3PL having the
            <code>tracking number</code> of the <code>respondents</code>.
          </li>
        </ul>

        <v-form ref="form">
          <v-file-input
            ref="inputFile"
            accept=".xls,.xlsx"
            @change="selectFile"
            label="Upload an Excel File"
            persistent-hint
            hint="The format of the file should be xlsx or xls."
            :rules="[(v) => !!v || 'File is required']"
          />
        </v-form>

        <v-btn
          class="primary mt-3"
          @click="upload"
          :disabled="this.uploadData.respondents.length == 0"
          >Upload</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceExcalibur.js";
import XLSX from "xlsx";
const service = new RestResource();

export default {
  data() {
    return {
      uploadData: {
        respondents: [],
      },
    };
  },

  methods: {
    selectFile(e) {
      if (e) {
        const file = e;

        let reader = new FileReader();
        reader.onload = () => {
          const bstr = reader.result;

          const wb = XLSX.read(bstr, { type: "binary" });

          const ws = wb.Sheets[wb.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

          this.uploadData.respondents = this.arrayOfArrayToJson(data[0], data);
        };

        reader.readAsBinaryString(file);
      }
    },

    arrayOfArrayToJson(heading, data) {
      let overall = [];

      for (let i = 1; i < data.length; i++) {
        let row = {};

        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j] != undefined) {
            row[heading[j]] = data[i][j];
          }
        }

        overall.push(row);
      }

      return overall;
    },

    async upload() {
      if (this.$refs.form.validate()) {
        this.$setLoader();

        service.uploadExcaliburUsers(this.uploadData).then(() => {
          this.$showSuccessMessage({ message: `Succesfully added ${this.uploadData.respondents.length} user(s)`});
        }).catch(e => {
           this.$showSuccessMessage(e.message);
        })
        this.$refs.inputFile.reset();
      }
    },
  },
};
</script>