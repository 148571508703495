import axios from "axios";
import Util from "../../Util.js"

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL;
var EXCALIBUR_BACKEND_URL = process.env.VUE_APP_EXCALIBUR_BACKEND_URL
export default class RestResource {

  async getexcaliburUsers(data) {
    const url = `${ADMIN_BACKEND_URL}/excalibur/getUsers`;
    return await axios.post(url, data, Util.setupHeaders());
  }

  async getExcaliburUser(params) {
    const url = `${ADMIN_BACKEND_URL}/excalibur/getUser`
    return await axios.post(url, params, Util.setupHeaders());
  }

  async uploadExcaliburUsers(data) {
    const url = `${EXCALIBUR_BACKEND_URL}/excalibur/updateTrackingNumber`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async getDeliveryStatus(data) {
    const url = `${EXCALIBUR_BACKEND_URL}/excalibur/getDeliveryStatus`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async uploadCallData(data) {
    const url = `${EXCALIBUR_BACKEND_URL}/excalibur/updateCallData`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async fetchCallData() {
    const url = `${EXCALIBUR_BACKEND_URL}/admin/getCallData`
    return await axios.post(url, {}, Util.setupHeaders());
  }
}